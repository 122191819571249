div.wrapper {
  width: 20px;
  height: 20px;
  border: 1px solid var(--border);
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  &.filled {
    border-color: rgb(80, 129, 240);
  }
  &.partial {
    border-color: var(--orange);
  }
  div {
    &.filled {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      background-color: rgb(80, 129, 240);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 12px;
        height: 12px;
        stroke: white;
        stroke-width: 4px;
      }
    }
    &.partial {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      background-color: var(--orange);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 12px;
        height: 12px;
        stroke: white;
        stroke-width: 4px;
      }
    }
  }
}
