div.wrapper {
  display: flex;
  height: 100vh;
  main {
    flex: 1;
    display: flex;
  }
  background: var(--background-secondary);
  div.sidebar {
    background: var(--background-primary);
    border-right: 1px solid var(--border);
    width: 260px;
    height: 100vh;
    display: flex;
    flex-direction: column;

    img.logo {
      height: 48px;
      display: block;
      margin: auto;
      margin-top: 24px;
    }
    footer {
      padding: 16px;
    }
    article {
      height: calc(100vh - 72px);
      overflow: auto;
    }
  }
}
