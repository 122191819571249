div.wrapper {
  flex: 1;
  &,
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-size: 96px;
    font-weight: 500;
    text-align: center;
  }
  h2 {
    font-size: 32px;
    font-weight: 500;
    margin-top: 12px;
    color: var(--black);
    text-align: center;
  }
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 48px;
    color: var(--gray);
    text-align: center;
  }
}
