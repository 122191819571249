div.outerWrapper {
  position: fixed;
  inset: 0;
  overflow: auto;
  div.backdrop {
    position: absolute;
    inset: 0;
    background-color: rgba(black, 0.8);
    z-index: 1;
  }
  div.wrapper {
    background: var(--background-secondary);
    width: 820px;
    height: calc(100vh - 48px);
    z-index: 2;
    position: relative;
    border-radius: 8px;
    margin: auto;
    margin-top: 24px;
    max-width: calc(100vw - 48px);
    display: flex;
    flex-direction: column;
    main.body {
      flex: 1;
      padding: 24px;
      overflow: auto;
    }
    header {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 16px;
      background-color: var(--border-25);
      border-bottom: 1px solid var(--border);
      position: relative;
      button {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        cursor: pointer;
        border-radius: 6px;
        color: var(--colour);
        &:hover {
          background: var(--border-75);
        }
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
      }
      h4 {
        color: var(--gray);
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        margin-top: 2px;
      }
    }
    footer.footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 24px;
      // background-color: var(--border-25);
      // border-top: 1px solid var(--border);
      button {
        padding: 16px 32px;
      }
      div.actionGroup {
        display: flex;
        gap: 16px;
      }
    }
  }
}
