div.wrapper {
  margin-bottom: 24px;
  position: relative;
  span.title {
    display: block;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    label {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      display: block;
      padding-left: 16px;
      color: var(--gray);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      &.collapsed {
        & > svg {
          transform: translateY(-50%);
        }
      }
      & > svg {
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        width: 24px;
        height: 24px;
        transition: 240ms;
      }
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    div.actions {
      position: absolute;
      right: 16px;
      display: flex;
      button {
        appearance: none;
        border: none;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 36px;
        height: 36px;
        background: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--colour);
        &:hover {
          background: var(--border-75);
        }
        &:active {
          background: var(--border);
        }
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
