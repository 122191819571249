span.wrapper {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 16px;
  background-color: var(--border-75);
  font-family: var(--font-code);
  border-radius: 6px;
  &.DUPLICATE {
    background: var(--secondary);
    color: white;
  }
  &.CHECK_NEEDED {
    background: var(--orange);
    color: white;
  }
  &.INVALID {
    background: var(--coral);
    color: white;
  }
  &.VALID {
    background: var(--green);
  }
}
