$initial-width: 45vw;

div.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: white;
  justify-content: space-between;
  width: 960px;
  max-width: calc(100vw - 48px);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1 {
    margin-top: 64px;
    margin-bottom: 12px;
  }
  div.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
