div.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  user-select: none;
  div.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 80px;
    border-top: 1px solid var(--border);
    div.content {
      height: 100%;
      display: flex;
      max-width: 960px;
      width: calc(100vw - 48px);
      margin: auto;
      align-items: center;
      div.progressWrapper {
        flex: 1;
        div.bar {
          height: 4px;
          flex: 1;
          background: var(--border);
          border-radius: 5px;
          margin: 0 24px;
          div.progress {
            background: var(--primary);
            transition: 120ms;
            height: 100%;
            border-radius: 5px;
            position: relative;
            &::after {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              height: 12px;
              display: block;
              content: '';
              border-radius: 50px;
              background: var(--primary);
            }
          }
        }
      }
    }
    button {
      padding: 0 16px;
      height: 42px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      &:last-child {
        svg {
          margin-left: 8px;
        }
      }
      svg {
        margin-right: 0;
      }
    }
  }
  div.header {
    height: 80px;
    border-bottom: 1px solid var(--border);
    position: relative;
    flex-shrink: 0;
    div.progress {
      position: absolute;
      height: 4px;
      width: 100%;
      bottom: -1px;
      left: 0;
      background: var(--primary);
      transition: 120ms;
    }
    div.content {
      display: flex;
      margin: auto;
      width: 960px;
      max-width: calc(100vw - 48px);
      align-items: center;
      height: 100%;
      h1 {
        font-size: 20px;
        color: var(--gray);
        font-weight: 500;
      }
      button.closeWrapper {
        appearance: none;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--gray);
        background: none;
        border: 1px solid var(--border);
        border-radius: 8px;
        margin-left: auto;
        cursor: pointer;
        outline: none;
        transition: 120ms;
        border-color: transparent;
        &:hover {
          background: var(--border-25);
          border-color: var(--border);
          color: var(--colour);
        }
        &:focus {
          color: var(--colour);
          border-color: var(--border);
          box-shadow: 0 0 0 4px var(--border-50);
        }
      }
    }
  }
  main {
    flex: 1;
    display: flex;
    overflow: auto;
  }
}
