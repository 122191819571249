label.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  span {
    padding-left: 4px;
  }
  span,
  a {
    color: var(--gray);
    strong {
      font-weight: 500;
    }
  }
}
