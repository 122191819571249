button.wrapper {
  height: var(--input-height);
  min-width: var(--input-height);

  appearance: none;
  border: 1px solid var(--border);
  border-radius: 8px;
  background: none;
  padding: 0 0px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  transition: 120ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  &.filled {
    padding: 0 24px;
    svg {
      margin-right: 12px;
    }
  }
  &:not(:disabled) {
    &:hover {
      background: var(--border);
    }
    &:focus {
      border-color: var(--border);
      box-shadow: 0 0 0 4px var(--border-50);
      &.primary {
        border-color: var(--primary-50);
        box-shadow: 0 0 0 4px var(--primary-25);
      }
    }
  }
  &:disabled {
    color: initial;
  }
  svg {
    width: 20px;
    height: 20px;
    &:last-child {
      margin-left: 12px;
    }
  }
  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
  &.primary {
    border: none;
    color: white;
    background: var(--primary);
    &:hover {
      background: var(--primary-hover);
    }
    &:active {
      background: var(--primary-active);
    }
  }
  &.link {
    border: none;
    opacity: 0.6;
    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
    }
  }
}
