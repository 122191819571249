@import '../variables';
form.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  --form-width: 380px;
  position: relative;
  overflow-y: auto;
  overflow-x: visible;
  padding-top: 80px;
  padding-bottom: 80px;
  height: fit-content;
  min-height: 100vh;
  padding: 0 24px;
  img {
    display: none;
    @media screen and (max-width: $screen-tablet) {
      display: block;
      height: 48px;
      position: absolute;
      top: 24px;
    }
  }
  h1,
  h2 {
    font-weight: 500;
    width: var(--form-width);
    max-width: calc(100vw - 48px);
    text-align: center;
  }
  h1 {
    font-size: 24px;
    color: var(--primary);
  }
  h2 {
    font-size: 16px;
    color: var(--gray);
  }
  div.input,
  label {
    width: var(--form-width);
    max-width: calc(100vw - 48px);
    min-width: unset;
  }
  div.row {
    display: flex;
    width: 100%;
    width: var(--form-width);
    max-width: calc(100vw - 48px);
    align-items: center;
    &.center {
      justify-content: center;
      a {
        margin: 0 !important;
      }
    }
    @media screen and (max-width: $screen-mobile) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 16px;
      button {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
    a {
      color: var(--gray);
      font-size: 14px;
      text-decoration: none;
      font-weight: 500;
      margin-right: 24px;
      &:first-child {
        margin-right: auto;
      }
      &:hover {
        text-decoration: underline;
      }
      @media screen and (max-width: $screen-mobile) {
        margin: 0;
        margin-right: 0;
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
  span.legal {
    &,
    a {
      font-size: 12px;
      max-width: var(--form-width);
      text-align: center;
      color: var(--gray);
    }
  }
}
