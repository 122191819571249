div.wrapper {
  background: var(--background-primary);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  margin-bottom: 24px;
  height: 100px;
  display: flex;
  flex-direction: column;
  transition: 120ms;
  outline: none;
  &:hover {
    background: var(--border-50);
  }
  &:focus {
    border-color: var(--secondary);
    box-shadow: 0 0 0 4px var(--secondary-25);
  }
  span.title {
    font-size: 18px;
    font-size: 600;
  }
  p {
    margin: 0;
    margin-top: 12px;
    font-size: 14px;
  }
  div.progressWrapper {
    width: 100%;
    background: var(--border-50);
    border-radius: 4px;
    position: relative;
    height: 4px;
    margin-top: auto;
    div.progress {
      z-index: 1;
      height: 4px;
      border-radius: 4px;
      background-color: var(--primary);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}
