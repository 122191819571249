div.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  header {
    height: calc(48px + 24px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border);
    padding: 0 24px;
    justify-content: space-between;
    div.content {
      display: flex;
      button {
        // padding: 16px;
        padding: 0px 16px;
        height: 42px;
        display: flex;
        align-items: center;
        line-height: 42px;
        font-size: 14px;
      }
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      button {
        display: flex;
        align-items: center;
        appearance: none;
        border: none;
        background: none;
        cursor: pointer;
        margin: 0;
        padding: 0;
        margin-right: 8px;
        padding: 8px;
        border-radius: 8px;
        margin-left: -8px;
        &:hover {
          background: var(--border-75);
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  main {
    flex: 1;
    padding: 24px;
    overflow: auto;
    flex-direction: column;
  }
}
