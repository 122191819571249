div.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  h1 {
    font-size: 100px;
    margin-bottom: 0px;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    // color: var(--primary);
    margin-bottom: 24px;
    text-transform: uppercase;
  }
  p {
    color: var(--gray);
    margin: 0;
    margin-bottom: 4px;
  }
}
div.todos {
  width: 100%;
  height: 100%;
  h3 {
    font-size: 18px;
  }
  div.surveys {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
}
