@import '../variables';
main.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  width: calc(100vw - 48px);
  max-width: 380px;
  margin: auto;
  min-height: 100vh;
  img {
    display: none;
    @media screen and (max-width: $screen-tablet) {
      display: block;
      height: 48px;
      position: absolute;
      top: 24px;
      object-fit: contain;
    }
  }
  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    margin-top: 96px;
    color: var(--primary);
  }
  h2 {
    font-size: 16px;
    margin-top: 48px;
    margin-bottom: 12px;
    color: var(--gray);
    font-weight: 500;
  }
  h3 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }
  & > * {
    width: 100%;
  }
  ol,
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 16px;
  }
  li:not(:last-child) {
    margin-bottom: 12px;
  }
  ul {
  }
  hr {
    border: none;
    height: 1px;
    background: var(--border);
    margin: 36px 0;
  }
  p {
    font-size: 16px;
    color: var(--gray);
    text-align: center;
  }
  button {
    margin-top: 48px;
  }
}
