div.wrapper {
  display: flex;
  gap: 24px;
  & > div {
    flex: 1;
  }
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}
