div.wrapper {
  background-color: var(--background-primary);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;
  overflow: hidden;
  max-width: calc(100vw - 304px);
  text-overflow: ellipsis;
  cursor: pointer;
  border: 1px solid var(--border);
  &:hover {
    background-color: var(--border-25);
  }
  div.header,
  div.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    strong {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      svg {
        margin-left: 8px;
        width: 20px;
        height: 20px;
      }
    }
    span {
      font-size: 14px;
      color: var(--gray);
      font-weight: 500;
    }
  }
  div.footer {
    margin-top: 4px;
  }
  span.code {
    font-family: var(--font-code);
    font-size: 14px;
    color: var(--gray);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
