header.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border);
  height: 72px;
  padding: 0 24px;
  img {
    height: 48px;
  }
  div.links {
    display: flex;
    gap: 24px;
    a {
      text-decoration: none;
      text-transform: uppercase;
      display: block;
      font-size: 14px;
      font-weight: 500;
      transition: 120ms;
      color: var(--colour);
      &:hover {
        transform: translateY(-4px);
      }
    }
  }
}
