div.wrapper {
  user-select: none;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
      a {
        padding: 8px 8px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
        padding: 8px 20px;
        display: flex;
        align-items: center;
        transition: 320ms;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 4px var(--border-50);
          background: var(--border);
        }
      }
      &:hover a {
        background: var(--border-75);
      }
      &:active a {
        background: var(--border);
      }
      &.selected a {
        background: var(--colour);
        color: white;
      }
      &.disabled a {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
}
