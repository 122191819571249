button.wrapper {
  appearance: none;
  border: none;
  cursor: pointer;
  background: transparent;
  user-select: none;
  outline: none;
  width: 100%;
  color: var(--colour);
  transition: 240ms;
  border-radius: 12px;

  padding-left: 16px;

  display: flex;
  align-items: center;
  height: 48px;
  &:hover {
    background: var(--border-50);
    color: var(--colour);
  }
  &:focus {
    background: var(--border-75);
    color: var(--colour);
  }
  &:focus {
    box-shadow: 0 0 0 4px var(--colour-25);
    color: var(--colour);
    background: var(--border-50);
    z-index: 8;
  }
  &.active {
    background: var(--colour);
    color: white;
  }
  span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    &.center {
      text-align: center;
      margin: auto;
      display: block;
    }
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
