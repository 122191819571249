div.wrapper {
  background-color: var(--background-primary);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid var(--border);

  div.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    button {
      padding: 12px 24px;
      font-weight: 500;
      svg {
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }
    }
    h2 {
      margin: 0;
      font-size: 16px;
    }
  }
}
