div.wrapper {
  display: flex;

  width: calc(100vw - 48px);
  margin: 0 auto;
  flex: 1;
  height: fit-content;
  div.questions {
    flex: 1;
    margin: 0 auto;
    div.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      div.actions {
        margin-top: 48px;
        margin-bottom: 48px;
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
  }
  div.explanation {
    border-left: 1px solid var(--border);
    padding-left: 48px;
    padding-top: 64px;
    padding-right: calc((100vw - 960px) / 2);
    flex: 1;
    max-width: 40vw;
    p {
      margin: 0;
      margin-bottom: 24px;
      color: var(--gray);
      font-size: 16px;
      line-height: 2;
    }
  }
}
