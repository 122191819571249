@import 'variables';
@import './components/progress.scss';

:root {
  --border: rgb(220, 222, 224);
  --border-25: #{transparentize(rgb(220, 222, 224), 0.75)};
  --border-50: #{transparentize(rgb(220, 222, 224), 0.5)};
  --border-75: #{transparentize(rgb(220, 222, 224), 0.25)};
  --placeholder: rgb(170, 172, 174);
  --gray: rgb(142, 141, 141);

  --colour: #333;
  --colour-25: #{transparentize(#333, 0.75)};
  --colour-50: #{transparentize(#333, 0.5)};
  --colour-75: #{transparentize(#333, 0.25)};

  --primary: #ff7700;
  --primary-hover: #{darken(#ff7700, 5%)};
  --primary-active: #{darken(#ff7700, 10%)};
  --primary-25: #{transparentize(#ff7700, 0.75)};
  --primary-50: #{transparentize(#ff7700, 0.5)};
  --primary-75: #{transparentize(#ff7700, 0.25)};

  --orange: #ff7700;
  --green: #c1f844;
  --green-25: #{transparentize(#c1f844, 0.75)};
  --green-50: #{transparentize(#c1f844, 0.5)};
  --green-75: #{transparentize(#c1f844, 0.25)};
  --coral: #ef2d56;
  --coral-25: #{transparentize(#ef2d56, 0.75)};
  --coral-50: #{transparentize(#ef2d56, 0.5)};
  --coral-75: #{transparentize(#ef2d56, 0.25)};

  --secondary: #{darken(rgb(165, 224, 223), 20%)};
  --secondary-25: #{transparentize(darken(rgb(165, 224, 223), 20%), 0.75)};
  --secondary-50: #{transparentize(darken(rgb(165, 224, 223), 20%), 0.5)};
  --secondary-75: #{transparentize(darken(rgb(165, 224, 223), 20%), 0.25)};

  --input-height: 48px;

  --font-title: 'Bebas Neue', cursive;
  --font-main: 'Poppins', cursive;
  --font-code: 'Fira Code', monospace;

  --background-primary: rgb(255, 255, 255);
  --background-secondary: rgb(240, 243, 245);
}

html,
body {
  padding: 0;
  margin: 0;
  color: var(--colour);
  font-family: var(--font-main);

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
