div.wrapper {
  min-height: var(--input-height);
  min-width: 360px;
  border: 1px solid var(--border);
  border-radius: 8px;
  display: flex;
  align-items: center;
  transition: 120ms;
  position: relative;
  font-family: var(--font-main);
  background: var(--background-primary);
  span.length {
    color: var(--gray);
    position: absolute;
    right: 12px;
  }
  &.focused {
    border-color: var(--primary-50);
    box-shadow: 0 0 0 4px var(--primary-25);
  }
  &.error {
    border-color: var(--coral);
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }
  div.error {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--coral);
    display: flex;
    align-items: center;
    // &:hover {
    //   div.popup {
    //   }
    // }
  }
  svg {
    margin-left: 12px;
    width: 20px;
    height: 20px;
  }

  div.chevron {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  select {
    cursor: pointer;
  }
  input,
  select,
  textarea {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    outline: none;
    background: none;
    font-size: 14px;
    padding: 0 16px;
    line-height: var(--input-height);
    outline: none;
    border-radius: 7px;
    overflow: hidden;
    font-family: var(--font-main);
    &::placeholder {
      color: var(--placeholder);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background: none;
      box-shadow: 0 0 0px 1000px white inset !important;
      color: var(--title) !important;
      -webkit-text-fill-color: black !important;
    }
  }
  textarea {
    resize: vertical;
    min-height: 160px;
    line-height: 1.6;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &.icon {
    input,
    textarea {
      padding-left: 8px;
    }
  }
}

div.popup {
  position: absolute;
  left: calc(100% + 8px);
  background: white;
  padding: 8px 16px;
  border: 1px solid var(--border);
  border-radius: 6px;
  opacity: 0;
  visibility: hidden;
  // transition: 120ms;
  font-size: 14px;
  opacity: 1;
  visibility: visible;
  pointer-events: none;
  transform: translateY(-50%);
}
