div.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  width: calc(100vw - 48px);
  h2 {
    margin-top: 48px;
    font-size: 24px;
    font-weight: 500;
    color: var(--colour);
    margin-bottom: 12px;
    text-align: center;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    color: var(--gray);
    text-align: center;
    margin: 0;
    margin-bottom: 32px;
    text-align: center;
  }
}
