div.wrapper {
  display: flex;
  button {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &:not(:first-child) {
      border-left: none;
    }
  }
}
