div.wrapper {
  overflow: auto;
  max-width: calc(100vw - 304px);
  background: var(--background-primary);
  border-radius: 8px;
  border: 1px solid var(--border);
  border-spacing: 0px;

  table {
    text-align: left;
    padding: 16px;
    min-width: 100%;
    td,
    th {
      padding-right: 16px;
      white-space: nowrap;
      &:not(:last-child) {
        padding-right: 12px;
      }
    }
    thead {
      user-select: none;
      tr {
        td,
        th {
          background: var(--border);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
          span {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
              margin-left: 24px;
            }
          }
        }
      }
    }
    tr {
      &.hoverable {
        color: var(--color-75);
        &:hover {
          color: var(--color);
          cursor: pointer;
          td {
            background: var(--border-25);
          }
        }
      }
      &.header {
        td {
          background: var(--border-50);
        }
      }
      td,
      th {
        padding: 12px 0;
        &:first-child {
          padding-left: 16px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          padding-right: 16px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}
