@import '../variables';
div.wrapper {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  &.grid {
    @media screen and (min-width: $screen-tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  button.optionWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 6px 10px;
    // border: 1px solid var(--border);
    border: none;
    border-radius: 8px;
    height: 48px;
    cursor: pointer;
    transition: 120ms;
    width: 340px;
    background-color: var(--border-50);
    &:focus {
      box-shadow: 0 0 0 4px var(--secondary-25);
      outline: none;
      border-color: var(--secondary);
    }
    &:hover {
      background: var(--border-75);
    }
    &.selected {
      // border-color: var(--secondary);
      // background: var(--secondary-50);
      span.prefix {
        background: var(--secondary);
      }
    }
    span.prefix {
      font-family: var(--font-code);
      padding: 4px 8px;
      border-radius: 3px;
      background: var(--gray);
      font-size: 14px;
      font-weight: 500;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      opacity: 1;
    }
    span.label {
      text-align: left;
      white-space: nowrap;
    }
  }
}
